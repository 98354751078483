@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

$titleFont: 'Raleway', sans-serif;
$textFont: 'Catamaran', sans-serif;
$bgClr: rgba(#0b0f27, .83);
$txtClr: #fff;
$animSpd: 750 * 1ms; // Change also in JS

$numOfSlides: 5; // Add new city in the array in JS and a new image below

$bgPics: (
        url('./assets/guangzhou.jpg') center center no-repeat,
        url('./assets/dubai.jpg') center center no-repeat,
        url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--3-min-min.jpg') center center no-repeat,
        url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--4-min-min.jpg') center center no-repeat,
        url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--5-min-min.jpg') center center no-repeat
);

@mixin mediaMaxW($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

:root {
  --card-width: 250px;
  --card-height: 350px;
  --card-transition-duration: 800ms;
  --card-transition-easing: ease;
}

@mixin underline {
  content: "";
  position: absolute;
  display: block;
  height: 3px;
  width: 1.6rem;
  bottom: -15px;
  border-radius: 25px;
  background-color: black;
}

* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: $textFont;
  scroll-behavior: smooth;
  overscroll-behavior: none;
  width: 100%;
  min-height: 100%;
  font-size: 1.125rem;
  font-weight: 200;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  display: inline-block;
}

i {
  color: white;
  font-size: 26px;
}

a, a:visited, a:hover {
  text-decoration: none;
  color: black;
}

/*Lenis Styling*/
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/*Page Styling*/
.navbar {
  padding: .5rem 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  transition: all 0.3s ease;
  background-color: transparent;

  .container {
    padding: 0 1.25rem;
  }
}

.navbar.scrolled {
  background-color: white;
  box-shadow: 4px 4px 8px 0 rgb(0 0 0 / 20%);

  .hamburger > span {
    background-color: black;
  }
}

.navbar.scrolled.hidden {
  transform: translateY(-100%);
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__links {
  display: none;
}

header .button-link {
  display: none;
}

.logo {
  width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburger {
  cursor: pointer;
}

.hamburger > span {
  display: block;
  background-color: white;
  width: 26px;
  height: 2px;
  transform-origin: 3px 1px;
  transition: all 300ms ease;
}

.hamburger > span:not(:first-child) {
  margin-top: 5px;
}

.navbar.open .hamburger > span:first-child {
  transform: rotate(45deg);
}

.navbar.open .hamburger > span:nth-child(2) {
  opacity: 0;
}

.navbar.open .hamburger > span:last-child {
  transform: rotate(-45deg);
}

.overlay {
  position: fixed;
  inset: 0 0 0 0;
  z-index: 2;
  background-image: linear-gradient(black, transparent);
}

.header__menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .75rem;
  padding: 2rem;
  border-radius: 5px;
  background: white;
  color: black;
  position: absolute;
  width: calc(100% - 2.75rem);
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2rem;
  z-index: 3;
}

.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}

.fade-in {
  animation: fade-in 0.25s ease forwards;
}

.fade-out {
  animation: fade-out 0.25s ease forwards;
}

.lock-scroll {
  overflow: hidden !important;
}

section {
  min-height: 100vh;
}

//Home Section
#home {
  .item {
    width: 200px;
    height: 300px;
    list-style-type: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 4px 4px 8px 0 rgb(0 0 0);
    transition: transform 0.1s, left 0.75s, top 0.75s, width 0.75s, height 0.75s;

    &:nth-child(1), &:nth-child(2) {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: none;
      border-radius: 0;
      box-shadow: none;
      opacity: 1;
    }

    &:nth-child(3) {
      left: 57%;
    }

    &:nth-child(4) {
      left: calc(57% + 220px);
    }

    &:nth-child(5) {
      left: calc(57% + 440px);
    }

    &:nth-child(6) {
      left: calc(57% + 660px);
      opacity: 0;
    }
  }

  .content {
    width: min(30vw, 400px);
    position: absolute;
    top: 50%;
    left: 3rem;
    transform: translateY(-50%);
    font: 400 0.85rem $titleFont;
    color: white;
    text-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: none;

    & .title {
      font-family: $textFont;
      font-style: italic;
      font-size: 36px;
    }

    & .description {
      line-height: 1.7;
      margin: 1rem 0 1.5rem;
      font-size: 0.8rem;
    }

    & a {
      width: fit-content;
      background-color: rgba(0, 0, 0, 0.1);
      color: white;
      border: 2px solid white;
      border-radius: 0.25rem;
      padding: 0.75rem;
      cursor: pointer;
    }
  }

  .item:nth-of-type(2) .content {
    display: block;
    animation: show 0.75s ease-in-out 0.3s forwards;
  }

  @keyframes show {
    0% {
      filter: blur(5px);
      transform: translateY(calc(-50% + 75px));
    }
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }

  .navigations {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    user-select: none;

    & .btn {
      background-color: rgba(255, 255, 255, 0.5);
      border: 2px solid rgba(0, 0, 0, 0.6);
      margin: 0 0.25rem;
      padding: 0.75rem;
      border-radius: 50%;
      cursor: pointer;
      height: 55px;

      img {
        display: inline-block;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  @media (width > 650px) and (width < 900px) {
    .content {
      & .title {
        font-size: 1rem;
      }

      & .description {
        font-size: 0.7rem;
      }

      & button {
        font-size: 0.7rem;
      }
    }
    .item {
      width: 160px;
      height: 270px;

      &:nth-child(3) {
        left: 50%;
      }

      &:nth-child(4) {
        left: calc(50% + 170px);
      }

      &:nth-child(5) {
        left: calc(50% + 340px);
      }

      &:nth-child(6) {
        left: calc(50% + 510px);
        opacity: 0;
      }
    }
  }

  @media (width < 650px) {
    .content {
      & .title {
        font-size: 0.9rem;
      }

      & .description {
        font-size: 0.65rem;
      }

      & button {
        font-size: 0.7rem;
      }
    }
    .item {
      width: 130px;
      height: 220px;

      &:nth-child(3) {
        left: 75%;
      }

      &:nth-child(4) {
        left: calc(75% + 140px);
      }

      &:nth-child(5) {
        left: calc(75% + 280px);
      }

      &:nth-child(6) {
        left: calc(75% + 420px);
        opacity: 0;
      }
    }
  }
}


.title {
  font-family: $titleFont;
  font-size: 50px;
  font-style: italic;
  margin: 0;
  font-weight: 900;
  opacity: 0.85;
}

.subtitle {
  margin-top: 0;
  font-size: 18px;
  text-align: center;
}

// Gallery Section
#gallery {
  min-height: 110vh;
  display: grid;
  place-items: center;
  padding: 1rem 0 2rem;

  .container {
    width: 85%;
    margin: 1.75rem auto 0;
    display: grid;
    grid-template-columns: 1.25fr 1fr 1fr;
    grid-template-rows: 1fr 1.25fr;
    grid-gap: 1.5rem;
    min-height: calc(100vh - 3rem);
  }

  .child {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    position: relative;
    box-shadow: 4px 4px 8px 0 #343434;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
  .child:nth-child(1) {
    background-image: url("assets/gallery/moscow.webp");
    grid-row: 1 / -1;
  }

  .child:nth-child(2) {
    background-image: url("assets/gallery/paris.jpg");
  }

  .child:nth-child(3) {
    background-image: url("assets/gallery/toledo.webp");
  }

  .child:nth-child(4) {
    background-image: url("assets/gallery/venice.jpg");
    grid-column: 2 /-1;
  }

  .location {
    font-size: 36px;
    font-weight: 700;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: black;
    color: white;
    font-family: $titleFont;
    position: absolute;
    bottom: 1rem;
    left: 2rem;
  }

  .country {
    font-size: 18px;
    font-weight: 600;
    color: black;
    font-family: $textFont;
    position: absolute;
    bottom: 0.75rem;
    left: 2rem;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: white;
  }

}

//Explore Section
#explore {
  overflow: hidden;

  .container {
    display: flex;
    justify-content: center;
    padding: 0.25rem 0 2rem;

    .title {
      font-family: $titleFont;
      font-style: italic;
      margin: 0;
      font-weight: 900;
      font-size: 50px;
      position: relative;
      top: -37%;
      z-index: 10;

      &:after {
        @include underline;
        bottom: -10px;
        width: 2.1rem;
      }
    }
  }

  .cont {
    overflow: hidden;
    position: relative;
    height: 103vh;
  }

  .slider {
    position: relative;
    height: 100%;
    transform: translate3d(0, 0, 0);
    will-change: transform;
    user-select: none;

    &.animation {
      transition: transform $animSpd ease-in-out;

      .slide__darkbg {
        transition: transform $animSpd ease-in-out;
      }

      .slide__text {
        transition: transform $animSpd ease-in-out;
      }

      .slide__letter {
        transition: transform $animSpd ease-in-out;
      }
    }
  }

  .slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @for $i from 1 through $numOfSlides {
      &--#{$i} {
        left: 100% * ($i - 1);
      }
    }

    &__darkbg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: translate3d(0, 0, 0);
      will-change: transform;
      z-index: 10;
    }

    &__text-wrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      z-index: 15;
    }

    &__letter {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translate3d(0, 0, 0);
      -webkit-text-fill-color: transparent !important;
      -webkit-background-clip: text !important;
      font: {
        size: 50vw;;
        weight: 800;
      }
      color: #000;
      z-index: 2;
      will-change: transform;
      user-select: none;
    }

    &__text {
      font: {
        size: 8vw;
        weight: 800;
      }
      text-transform: uppercase;
      transform: translate3d(0, 0, 0);
      letter-spacing: 12px;
      color: $txtClr;
      will-change: transform;
      user-select: none;
    }
    &__text:nth-child(odd) {
      z-index: 2;
    }
    &__text:nth-child(even) {
      z-index: 1;
    }

    @each $item in $bgPics {
      $i: index($bgPics, $item);
      &--#{$i} {
        &__darkbg {
          left: -50% * ($i - 1);
          background: $item;
          background-size: cover;
          background-position: 0 center, 0 center;
          transform: translate3d(0, 0, 0);
          will-change: transform;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $bgClr;
          }
        }
        &__letter {
          background: $item;
          background-position: 0 center, 0 center;
          background-size: cover;
        }
      }
    }
  }

  .nav {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    list-style-type: none;
    z-index: 10;
    padding: 0;

    &__slide {
      position: relative;
      display: inline-block;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 2px solid #fff;
      margin-left: 10px;
      cursor: pointer;
      @include mediaMaxW(400px) {
        width: 22px;
        height: 22px;
      }
      &:hover:after {
        transform: translate(-50%, -50%) scale(1,1);
        opacity: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0,0);
        width: 75%;
        height: 75%;
        border-radius: 50%;
        background-color: #fff;
        opacity: 0;
        transition: 300ms;
      }

      &--1 {
        margin-left: 0;
      }
    }
  }

  .nav-active {
    &:after {
      transform: translate(-50%, -50%) scale(1,1);
      opacity: 1;
    }
  }
}

//Details Section
#details {
  padding-top: 1.5rem;
  min-height: 50vh;

  .title {
    font-family: $titleFont;
    font-style: italic;
    margin: 0;
    font-weight: 900;
    font-size: 50px;
    text-align: center;
  }


  .container {
    padding: 25px 0 50px;
    max-width: 900px;
    width: 100%;

    .location {
      margin: 0;
      text-align: center;
      font-weight: 600;
      font-size: 24px;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .nav-btn {
      color: #bd1636;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #bd1636 !important;
  }
}

//About Section
#abouts {
  display: grid;
  grid-template-rows: auto auto;
  place-items: center;
  padding: 1rem 0;
  grid-gap: 1rem;

  .container {
    width: 85%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
  }

  .flex-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem
  }

  .children {
    text-align: center;
    padding: 1rem;
    border-radius: 20px;
    width: calc(50% - 1rem);
    box-shadow: 4px 4px 8px #0003;
  }

}


#apps {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0 1rem;

  img {
    border-radius: 20px;
    height: 490px;
    aspect-ratio: 1 / 2;
    box-shadow: 4px 4px 8px #0003;
  }

  .text {
    font-size: 34px;
    font-weight: 600;
    text-align: center;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app {
    &-btn {
      width: 45%;
      max-width: 160px;
      color: #fff;
      margin: 20px 10px;
      text-align: left;
      border-radius: 5px;
      text-decoration: none;
      font-family: "Lucida Grande", sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      &.blu {
        background-color: #bd1636;
        transition: all 0.2s linear;
        &:hover {
          box-shadow: 4px 4px 8px 0 rgb(0 0 0);
        }
      }
      i {
        width: 20%;
        text-align: center;
        font-size: 28px;
        margin-right: 7px;
      }
      .big-txt {
        font-size: 17px;
        text-transform: capitalize;
      }
    }
  }

}


//Contact Section
#contact {
  min-height: 100vh;
  background-image: url("assets/tour.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  place-items: center;
  text-align: center;
  color: white;

  .container {
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
  }

  .contact {
    font-size: 13px;
    padding: 0.75rem 1.4rem;
    margin-top: 2rem;
    border-radius: 100px;
    border: 1px solid #bd1636;
    background-color: #bd1636;
    cursor: pointer;
    color: white;
    transition: all 0.2s ease;

    &:hover {
      box-shadow: 4px 4px 8px 0 rgb(0 0 0);
      opacity: 0.8;
    }
  }
}

.footer-content {
  background-color: #bd1636;
  padding: 0 5% 1rem;
  color: white;
  font-size: 17px;

  .category {
    font-size: 24px;
  }

  .container {
    grid-template-columns: 1fr;
    justify-items: start;
    display: grid;
    width: 80%;
    margin: 0 auto;
  }

  .column {
    min-width: 245px;
  }

  .navigation {
    list-style: none;
    text-align: left;
    padding-left: 0;
    width: 100%;

    a, a:visited, a:active {
      color: rgba(255, 255, 255, 0.9);
      transition: color 0.2s ease;

      &:hover {
        color: black;
      }
    }

    li {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 1rem;
      margin: 0 auto .75rem;

      img {
        width: 26px;
        height: 26px;
        display: block;
      }
    }
  }

  .fieldInput {
    display: flex;
    align-items: center;
    margin: 3rem auto auto;
    width: 100%;
    height: 50px;
    position: relative;
    box-shadow: 4px 4px 8px 0 rgb(0 0 0);
    border-radius: 35px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.95);
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: border-color 0.2s ease;

    &:hover, &:focus-within {
      border: 1px solid rgba(255, 255, 255, 0.5);
    }

    .form-input {
      background: none;
      border: none;
      padding: 0 2px;

      &:focus {
        outline: none;
      }
    }

    .form-submit {
      font-size: 12px;
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
      height: 38px;
      padding: 0 1.2rem;
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background-color: #bd1636;
      cursor: pointer;
      color: white;
      transition: all 0.2s ease;

      &:hover {
        box-shadow: 5px -2px 81px 1px rgb(0, 0, 0);
      }
    }
  }

  .social_profile {
    margin-top: 40px;

    .list {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: .8rem;
    }

    ul {
      list-style: outside none none;
      margin: 0;
      padding: 0;
    }

    ul li a {
      text-align: center;
      text-transform: uppercase;
      transition: all 0.3s ease 0s;
      color: #fff;
      border-radius: 5rem;
      width: 50px;
      height: 50px;
      line-height: 3.2rem;
      border: 1px solid rgba(255, 255, 255, 0.2);
      display: block;
    }

    ul li a:hover {
      border: 0;
      box-shadow: 4px 4px 8px 0 rgb(0 0 0);
    }
  }

  .copyright {
    width: 87.5%;
    margin: 1rem auto 0;
    padding-top: 1.5rem;
    font-size: 16px;
    color: #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    text-align: center;
    opacity: 0.8;

    a {
      color: white;
      cursor: pointer;
      transition: all 0.2s ease 0s;

      &:hover {
        color: black;
      }
    }
  }
}


@media (min-width: 768px) {
  .header__links {
    font-size: 0.875rem;
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: color .3s ease-in-out;

    a {
      position: relative;
      color: rgb(255, 255, 255);
      font-weight: 500;
      text-transform: uppercase;

      &:hover {
        color: rgba(0, 0, 0, 0.8);
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        height: 3px;
        left: 0;
        right: 0;
        bottom: -10px;
        border-radius: 25px;
        background-color: transparent;
        transition: background-color 0.3s ease-in-out;
      }

      &:hover::after {
        background-color: rgba(0, 0, 0, 0.8);;
      }

    }
  }

  .navbar.scrolled a {
    color: hsl(233, 8%, 30%);
  }

  .hamburger, .header__menu, .overlay {
    display: none;
  }

  header {
    .button-link {
      font-size: 15px;
      padding: 0.6em 1.2em;
      border: 1px solid #bd1636;
      font-weight: 400;
      border-radius: 10px;
      cursor: pointer;
      color: white;
      display: block;
      box-shadow: none;
      transition: all 300ms ease;

      &:hover {
        background-color: #bd1636;
        box-shadow: 4px 4px 8px 0 rgb(0 0 0);
        color: white;
      }
    }

    .button-link.scrolled {
      transition: all 300ms ease;
      background-color: #bd1636;
      box-shadow: none;
      color: white;

      &:hover {
        box-shadow: 4px 4px 8px 0 rgb(0 0 0);
      }
    }
  }

  .logo {
    height: 100%;
    align-self: center;
    display: flex;
    align-items: center;
  }

  .nav__list {
    display: flex;
    gap: 3rem;
  }

  .footer-content {

    .container {
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      justify-items: center;

      .category {
        opacity: 0.85;
        width: 100%;
      }
    }
  }
}


@media (max-width: 768px) {

  #abouts {
    grid-gap: 0;

    .container {
      grid-template-columns: 1fr;
    }

    .children {
      min-width: 250px;
    }
  }

  #gallery {
    .container {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
    }
  }

  #details {
    .nav-btn {
      display: none;
    }
  }
}

@media (max-width: 430px) {
  #apps {
    grid-template-columns: 1fr;
    padding: 3rem 0;
  }

  #gallery {
    .container {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    .child {
      height: 300px;
    }

    .child:nth-child(1) {
      grid-row: auto;
    }

    .child:nth-child(4) {
      grid-column: auto;
    }
  }
}